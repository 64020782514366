#bmV0aXplbmFjbS1yZXdhcmRz {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-direction: normal;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

.cx-widget {
  right: 0 !important;
}

.cx-widget input[type="text"],
.cx-widget textarea {
  font-size: 16px;
}

.cx-widget .cx-webchat .cx-transcript-wrapper {
  height: calc(90vh - 8rem) !important;
}

.cx-widget .cx-webchat div.cx-input-container textarea {
  font-size: 16px;
}

.cx-widget .cx-webchat .cx-mobile .cx-input-container {
  padding: 14px 0 14px 14px !important;
}
