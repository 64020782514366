@font-face {
  font-family: "FS Albert Pro";
  src: local("FS Albert Pro"), local("FSAlbertPro"),
    url("https://acm-assets.eco.astro.com.my/fonts/FSAlbertPro.woff2")
      format("woff2"),
    url("https://acm-assets.eco.astro.com.my/fonts/FSAlbertPro.woff")
      format("woff");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "FS Albert Pro";
  src: local("FS Albert Pro Bold"), local("FSAlbertPro-Bold"),
    url("https://acm-assets.eco.astro.com.my/fonts/FSAlbertPro-Bold.woff2")
      format("woff2"),
    url("https://acm-assets.eco.astro.com.my/fonts/FSAlbertPro-Bold.woff")
      format("woff");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "FS Albert Pro";
  src: local("FS Albert Pro ExtraBold"), local("FSAlbertPro-ExtraBold"),
    url("https://acm-assets.eco.astro.com.my/fonts/FSAlbertPro-ExtraBold.woff2")
      format("woff2"),
    url("https://acm-assets.eco.astro.com.my/fonts/FSAlbertPro-ExtraBold.woff")
      format("woff");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}
